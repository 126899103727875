<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-container>
    <v-dialog v-model="addEditDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t(formTitle) }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form v-model="valid" ref="modalForm" class="py-4 modal-form">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <label>{{ $t("Name") }} </label>
                  <v-text-field
                    v-model="editedItem.name"
                    :rules="[validationRules.required]"
                    @focus="onFocus()"
                    solo
                    @blur="generateRandomCode()"
                  ></v-text-field>
                  <div
                    v-if="validation_errors.name != ''"
                    class="validation-div div-validation"
                  >
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.name"
                      :key="index"
                      style="margin-top: 15px !important"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <label>{{ $t("Code") }} </label>
                  <v-text-field
                    v-model="editedItem.code"
                    :rules="[validationRules.required]"
                    @focus="onFocus()"
                    solo
                    :disabled="editedIndex > -1"
                  ></v-text-field>
                  <div
                    v-if="validation_errors.code != ''"
                    class="validation-div div-validation"
                  >
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.code"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <label>{{ $t("Warehouse Member") }} </label>
                  <v-select
                    v-model="editedItem.warehouse_id"
                    :items="allWarehouseStaff"
                    solo
                    persistent-hint
                    item-text="name"
                    item-value="id"
                  ></v-select>
                  <div
                    v-if="validation_errors.warehouse_id != ''"
                    class="validation-div div-validation"
                  >
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.warehouse_id"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <label>{{ $t("Canteen-Staff") }} </label>
                  <v-select
                    v-model="editedItem.canteen_staff"
                    :items="staff"
                    solo
                    multiple
                    persistent-hint
                    item-text="name"
                    item-value="id"
                  ></v-select>
                  <div
                    v-if="validation_errors.canteen_staff != ''"
                    class="validation-div div-validation"
                  >
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.canteen_staff"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn class="modal-btn-cancel" @click="close">{{
            $t("Close")
          }}</v-btn>
          <v-btn
            class="modal-btn-save"
            @click="save"
            :loading="loading"
            :disabled="loading"
            >{{ $t("Save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="snack_color"
      rounded="pill"
      top
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!-- ---------------------end SnackBar--------------------- -->
    <v-row justify="center">
      <v-dialog v-model="confirm_dialog" max-width="500">
        <v-card>
          <v-card-title class="text-h6">
            Are you sure you want to edit Canteen ?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="alert-cancel-btn"
              text
              @click="confirm_dialog = false"
            >
              No
            </v-btn>
            <v-btn class="alert-save-btn" text @click="update"> Yes </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { validationMixin } from "../mixins/validation-mixin";
import HttpService from "../services/HttpService.js";
// import ACL from "../acl.js";
export default {
  mixins: [validationMixin],
  props: ["addEditDialog", "editedIndex"],
  data: () => ({
    message: "",
    snackbar: false,
    timeout: 4000,
    snack_color: "#ffffff",
    loading: false,
    valid: false,
    confirm_dialog: false,
    // editedIndex: -1,
    editedItem: {
      name: "",
      code: "",
      warehouse_id: "",
      canteen_staff: [],
    },
    defaultItem: {
      name: "",
      code: "",
      warehouse_id: "",
      canteen_staff: [],
    },
    validation_errors: {
      name: [],
      code: [],
      warehouse_id: [],
      canteen_staff: [],
    },
    allWarehouseStaff: [],
    // allCanteenStaff: [],
    unassignedStaff: [],
    staff: [],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Canteen" : "Edit Canteen";
    },
  },

  methods: {
    setEditedItem(item) {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, item);
        this.staff = this.staff.concat(this.editedItem.canteen_staff_edit);
      });
      this.validation_errors = Object.assign(
        {},
        {
          name: [],
          code: [],
          warehouse_id: [],
          canteen_staff: [],
        }
      );
    },
    onFocus() {
      this.$refs.modalForm.resetValidation();
    },

    generateRandomCode() {
      if (this.editedIndex == -1) {
        if (this.editedItem.name != "" && this.editedItem.name.length >= 1) {
          //generate random code logic
          var noVowels = this.editedItem.name.replace(/[aeiou]/gi, "");

          this.editedItem.code =
            noVowels +
            // this.editedItem.name.charAt(0) +
            // this.editedItem.name.charAt(2) +
            // this.editedItem.name.charAt(4) +
            "-" +
            Math.floor(Math.random() * 90 + 10);
        }
      }
    },

    close() {
      // Child Shouldn't change parent property which is passed as props
      // this.addEditDialog = false;
      // Instead, It should call an event to call a function in parent to change the proerty to false
      this.$emit("closeDialog");
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.staff = this.unassignedStaff;
      });
      this.$refs.modalForm.resetValidation();
    },

    save() {
      if (this.editedIndex > -1) {
        // edit mode
        if (this.valid) {
          this.confirm_dialog = true;
        } else {
          this.$refs.modalForm.validate();
        }
      } else {
        // create mode
        this.store();
      }
    },

    update() {
      if (this.valid) {
        this.loading = true;
        HttpService.post(
          "canteens" + "/" + this.editedItem.id,
          this.editedItem,
          true
        ).then((response) => {
          this.message = response.data.status.message;
          if (response.data.status.error == false) {
            this.confirm_dialog = false;
            this.snack_color = "success";
            this.snackbar = true;
            this.loading = false;
            this.close();
            this.getUnAssignedStaff();
          } else {
            this.confirm_dialog = false;
            this.loading = false;
            // if there's an error
            if (
              Object.keys(response.data.status.validation_errors).length > 0
            ) {
              // if there's validation errors from backend
              this.validation_errors = response.data.status.validation_errors;
            } else {
              // if there's an error but it's not validation error
              this.snack_color = "error";
              this.snackbar = true;
            }
          }
          this.$emit("getData");
        });
      } else {
        this.$refs.modalForm.validate();
      }
    },

    store() {
      if (this.valid) {
        this.loading = true;
        HttpService.post("canteens", this.editedItem, true).then((response) => {
          this.message = response.data.status.message;
          if (response.data.status.error == false) {
            this.snack_color = "success";
            this.snackbar = true;
            this.loading = false;
            this.close();
            this.getUnAssignedStaff();
          } else {
            this.loading = false;
            // if there's an error
            if (
              Object.keys(response.data.status.validation_errors).length > 0
            ) {
              // if there's validation errors from backend
              this.validation_errors = response.data.status.validation_errors;
            } else {
              // if there's an error but it's not validation error
              this.snack_color = "error";
              this.snackbar = true;
            }
          }
          this.$emit("getData");
        });
      } else {
        this.$refs.modalForm.validate();
      }
    },

    getAllWarehouseStaff() {
      HttpService.get("canteens/allWarehouseStaff", "", true).then(
        (response) => {
          this.allWarehouseStaff = response.data.data;
          // console.log("my allWarehouseStaff is = ", this.allWarehouseStaff);
          // console.log("ACL.Role('Super-Admin')", ACL.Role("Super-Admin"));
        }
      );
    },

    getUnAssignedStaff() {
      HttpService.get("canteens/allCanteenStaff", "", true).then((response) => {
        this.unassignedStaff = response.data.data;
        this.staff = this.unassignedStaff;
        // console.log("my UnassignedStaff is = ", this.unassignedStaff);
      });
    },
  },

  created() {
    this.getAllWarehouseStaff();
    this.getUnAssignedStaff();
  },
};
</script>
<style></style>
