<template>
  <v-form ref="SettingsUpdate" v-model="valid">
    <label for="">School Name (Arabic)</label>
    <v-text-field
      v-model="school_arabic_name"
      single-line
      :rules="[
        validationRules.required,
        CheckArabicCharactersOnly(school_arabic_name),
      ]"
      required
      solo
    ></v-text-field>

    <label for="">School Name (English)</label>
    <v-text-field
      v-model="school_english_name"
      single-line
      :rules="[validationRules.required, validationRules.onlyEnglish]"
      required
      solo
    ></v-text-field>

    <!-- :rules="[validationRules.required]" -->
    <v-file-input
      accept="image/png, image/jpeg, image/bmp"
      placeholder="School Logo"
      prepend-icon="mdi-camera"
      @change="onFileUpload($event)"
    ></v-file-input>
    <!-- src="[object Object]" -->
    <div
      v-if="validation_errors.school_logo != ''"
      class="validation-div div-validation"
    >
      <p
        class="red--text"
        v-for="(error, index) in validation_errors.school_logo"
        :key="index"
      >
        {{ error }}
      </p>
    </div>

    <img
      v-if="
        imagrURL != 'http://sms-backend.test' && imagrURL != '[object Object]'
      "
      :src="imagrURL"
      length="150 px"
      width="150 px"
    />
    <br />
    <br />
    <br />

    <v-btn
      color="#7297ff"
      style="color: white"
      :loading="loading"
      :disabled="loading"
      @click="Update"
    >
      SAVE
    </v-btn>

    <!-- --------------------- -->

    <!-- --------------------- -->

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="snack_color"
      rounded="pill"
      top
    >
      {{ text_snack }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-form>
</template>

<script>
import { validationMixin } from "../mixins/validation-mixin";
import HttpService from "../services/HttpService.js";
export default {
  mixins: [validationMixin],
  data() {
    return {
      valid: true,
      school_english_name: "",
      school_arabic_name: "",
      school_logo: "",
      file: "",
      imagrURL: null,
      snackbar: false,
      text_snack: "",
      timeout: 4000,
      snack_color: "",
      loading: false,
      validation_errors: {
        school_logo: [],
      },
    };
  },
  methods: {
    onFileUpload(event) {
      console.log(event.target);
      this.school_logo = event;
    },

    Update() {
      console.log("this .valid", this.valid);

      if (this.valid) {
        this.loading = true;
        var formData = new FormData();
        formData.append("school_english_name", this.school_english_name);
        formData.append("school_arabic_name", this.school_arabic_name);
        formData.append("school_logo", this.school_logo);
        HttpService.post("setting", formData, true).then((response) => {
          this.text_snack = response.data.status.message;
          if (response.data.status.error == false) {
            localStorage["school_logo"] = response.data.data.school_logo;
            this.snack_color = "success";
            this.snackbar = true;
            this.getStting();
            this.loading = false;
            this.validation_errors = {
              school_logo: [],
            };
            // delete formData.school_logo;
            // console.log(formData.delete("school_logo"));

            setTimeout(function () {
              //   this.getStting();
              window.location.reload();
              //   this.loading = false;
            }, 1000);
          } else {
            this.snack_color = "error";
            this.snackbar = true;
            this.validation_errors = response.data.status.validation_errors;
            console.log(this.validation_errors);
            this.loading = false;

            // setTimeout(function () {
            //   // window.location.reload();
            // }, 5000);
          }
        });
      } else {
        this.$refs.SettingsUpdate.validate();
      }
    },

    getStting() {
      HttpService.get("setting", "", true).then((response) => {
        if (response.data.status.error == false) {
          this.school_arabic_name = response.data.data.school_arabic_name;
          this.school_english_name = response.data.data.school_english_name;
          this.imagrURL = response.data.data.school_logo;
        }
      });
    },
  },

  mounted() {
    this.getStting();
    console.log("VUE_APP_API_URL", process.env.VUE_APP_API_URL);
  },
};
</script>
