<template>
  <v-container>
    <v-row class="">
      <v-col cols="12">
        <DataTableComponent
          ref="dataTableComponent"
          v-on:deletedItem="deleteThisItem($event)"
          v-on:deactivatedItem="deactivateThisItem($event)"
          :table="tableData"
        ></DataTableComponent>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialogDelete" max-width="500">
        <v-card>
          <v-card-title class="text-h6"> </v-card-title>
          <span style="padding: 20px">
            This Product cannot be removed, as it has quantities.
          </span>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="alert-cancel-btn" text @click="dialogDelete = false">
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="snack_color"
      rounded="pill"
      top
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import DataTableComponent from "../components/DataTableComponent.vue";
import HttpService from "../services/HttpService.js";

export default {
  name: "Product",
  components: {
    DataTableComponent,
  },
  data() {
    return {
      message: "",
      snackbar: false,
      timeout: 4000,
      snack_color: "success",
      tableData: {
        parent: "product-view",
        title: "Product",
        headersData: [
          {
            text: this.$i18n.t("Name"),
            value: "en.name",
            width: "15%",
          },
          {
            text: this.$i18n.t("Arabic Name"),
            value: "ar.name",
            width: "15%",
          },
          {
            text: this.$i18n.t("Code"),
            value: "code",
            width: "15%",
            sortable: false,
          },
          {
            text: this.$i18n.t("Value in Points"),
            value: "selling_points",
            width: "15%",
            sortable: false,
          },
          {
            text: this.$i18n.t("Sellig Price"),
            value: "selling_price",
            width: "15%",
            sortable: false,
          },
          {
            text: this.$i18n.t("Actions"),
            value: "actions",
            sortable: false,
            width: "12%",
          },
        ],
        endpoints: {
          list: "products",
          create: "products",
          update: "products",
          delete: "products",
          search: "yes",
          deactivate: "yes",
        },
        activation: {
          isActive:
            "Turn off to stop future orders as well as future quantities for this product",
          isInactive:
            "Turn on to allow future orders as well as future quantities for this product",
        },
      },
      dialogDelete: false,
    };
  },
  methods: {
    deleteThisItem(event) {
      // console.log("deleted event = ", event);
      HttpService.get("products/hasQuantity/", event.id, true).then(
        (response) => {
          // console.log("before del response", response);
          this.isPlacedQuantity = response.data;
          // console.log("isPlacedQuantity = ", this.isPlacedQuantity); //yes
          if (this.isPlacedQuantity == false) {
            HttpService.delete("products/deleteProduct/" + event.id, true).then(
              // eslint-disable-next-line no-unused-vars
              (response) => {
                // console.log("you can delete ");
                // console.log("delete response = ", response);
                this.message = " Product deleted successfully";
                this.snack_color = "success";
                this.snackbar = true;
                this.loading = false;
                this.$refs.dataTableComponent.getDataFromApi();
                // console.log("hamada getDataFromApi");
              }
            );
          } else {
            this.dialogDelete = true;
          }
        }
      );
    },

    deactivateThisItem(event) {
      console.log(event.id);
      HttpService.post("products/deActivateProduct/" + event.id, {}, true).then(
        // eslint-disable-next-line no-unused-vars
        (response) => {
          console.log("deActivateProduct = ", response.data.data.active);
        }
      );
    },
  },
};
</script>

<style></style>
