<template>
  <v-container>
    <v-row class="">
      <v-col cols="12">
        <DataTableComponent :table="tableData"></DataTableComponent>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DataTableComponent from "../components/DataTableComponent.vue";
export default {
  name: "Staff",
  components: {
    DataTableComponent,
  },
  data() {
    return {
      tableData: {
        parent: "staff-view",
        title: "Staff Member",
        headersData: [
          {
            text: this.$i18n.t("Name"),
            value: "name",
            width: "12%",
          },
          {
            text: this.$i18n.t("Email address"),
            value: "email",
            width: "12%",
            sortable: false,
          },
          {
            text: this.$i18n.t("National ID"),
            value: "national_id",
            width: "12%",
            sortable: false,
          },
          {
            text: this.$i18n.t("Mobile number"),
            value: "mobile",
            sortable: false,
            width: "12%",
          },
          {
            text: this.$i18n.t("Password"),
            value: "plain_password",
            type: "password",
            sortable: false,
            width: "12%",
          },
          {
            text: this.$i18n.t("Role(s)"),
            value: "role_name",
            sortable: false,
            width: "12%",
          },
          {
            text: this.$i18n.t("Canteen"),
            value: "canteen_name",
            sortable: false,
            width: "12%",
          },
          {
            text: this.$i18n.t("Actions"),
            value: "actions",
            sortable: false,
            width: "12%",
          },
        ],
        endpoints: {
          list: "staff",
          create: "staff",
          update: "staff",
          // delete: "staff",
        },
      },
    };
  },
};
</script>

<style></style>
