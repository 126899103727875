<template>
  <v-container>
    <v-row class="headerTable-div2" style="width: 50%">
      <v-col md="4" sm="12">
        <h3 class="custom-header main-color">{{ title }}</h3>
      </v-col>
    </v-row>
    <!-- Children -->
    <v-btn
      :class="selectedStudentID == child.id ? 'isBlue' : ''"
      v-for="child in children"
      :key="child"
      rounded
      color="#EFEFEF"
      dark
      style="
        margin: 10px;
        margin-left: 0px;
        text-transform: capitalize;
        color: #979797;
        width: auto;
      "
      @click="whoSelected(child)"
    >
      {{ child.name }}
    </v-btn>
    <!-- Children -->
    <v-row>
      <v-col cols="6" style="position: relative">

        <v-data-table
          :headers="table.headersData"
          :items="dataTable"
          :options.sync="options"
          class="elevation-1 orderTable"
          item-key="id"
          hide-default-footer
        >
        </v-data-table>

        <div class="text-center pt-2">
          <v-pagination
            v-model="pagination.page"
            :length="pagination.length"
            :circle="true"
            :page="pagination.page"
            :total-visible="pagination.totalVisible"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
          ></v-pagination>
        </div>
      </v-col>
      <EmptySpace></EmptySpace>
    </v-row>
  </v-container>
</template>

<script>
import EmptySpace from "../components/EmptySpace.vue";
import HttpService from "../services/HttpService.js";

export default {
  components: {
    EmptySpace,
  },
  props: ["order"],

  data() {
    return {
      children: 0,
      dataTable: [],
      selectedStudentID: -1,
      title: "Transfers",
      pagination: {
        page: 1,
        length: 0,
        totalVisible: "",
      },
      options: {
        itemsPerPage: 15,
      },
      table: {
        headersData: [
          {
            text: this.$i18n.t("Points"),
            value: "points",
            sortable: false,
          },
          {
            text: this.$i18n.t("Date & Time"),
            value: "created_at",
            sortable: false,
          },
          {
            text: this.$i18n.t("Transferd by"),
            value: "transferd_by",
            sortable: false,
          },
        ],
      },
    };
  },
  methods: {
    whoSelected(child) {
      console.log(child);
      this.selectedStudentID = child.id;
      this.getAllTransfers();
    },

    getChildren() {
      HttpService.get("wallet/myChildren", "", true).then((response) => {
        this.children = response.data.data;
        this.selectedStudentID = this.children[0].id;
        this.getAllTransfers();
      });
    },

    setPaginationParameters(response) {
      this.pagination.length = response.data.data.allTransfers.last_page;
      if (this.pagination.length > 5) this.pagination.totalVisible = 5;
    },

    getAllTransfers() {
      let queryString = "?page=" + this.pagination.page;
      HttpService.get(
        "wallet/allTransfers/" + this.selectedStudentID,
        queryString,
        true
      ).then((response) => {
        this.setPaginationParameters(response);
        this.dataTable = response.data.data.allTransfers.data;
        console.log("response", response);
      });
    },
  },
  created() {
    this.getChildren();
  },

  watch: {
    "pagination.page": {
      handler() {
        this.getAllTransfers();
      },
      deep: true,
    },
  },
  mounted() {},
};
</script>

<style>
.isBlue {
  color: #5a86ff !important;
  background-color: rgba(90, 134, 255, 0.3) !important;
}

.searchInput {
  border-radius: 30px;
  padding: 10px;
  /* width: 793px;
  height: 51px; */
}
</style>
