<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-container>
    <v-dialog v-model="addEditDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t(formTitle) }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form v-model="valid" ref="modalForm" class="py-4 modal-form">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <label>{{ $t("Name") }} </label>
                  <v-text-field
                    v-model="editedItem.name"
                    :rules="[
                      validationRules.required,
                      validationRules.onlyِArabicOrEnglishLetters,
                    ]"
                    solo
                  ></v-text-field>
                  <div
                    v-if="validation_errors.name != ''"
                    class="validation-div div-validation"
                  >
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.name"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>
              </v-row>
              <!-- ---------------------------------- -->
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <label>{{ $t("Email address") }} </label>
                  <v-text-field
                    v-model="editedItem.email"
                    :rules="[
                      validationRules.required,
                      validationRules.validEmail,
                    ]"
                    solo
                  ></v-text-field>
                  <div
                    v-if="validation_errors.email != ''"
                    class="validation-div div-validation"
                  >
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.email"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>
              </v-row>
              <!-- ---------------------------------------------- -->
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <label>{{ $t("National ID") }} </label>
                  <v-text-field
                    v-model="editedItem.national_id"
                    :rules="[
                      validationRules.required,
                      validationRules.onlyNumbersAndDashes,
                    ]"
                    solo
                  ></v-text-field>
                  <div
                    v-if="validation_errors.national_id != ''"
                    class="validation-div div-validation"
                  >
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.national_id"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>
              </v-row>
              <!-- ----------------------type="password"---------------------------- -->
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <label>{{ $t("Password") }} </label>
                  <v-text-field
                    v-if="editedIndex == -1"
                    v-model="editedItem.password"
                    :rules="[validationRules.required]"
                    :append-icon="value ? 'visibility_off' : 'visibility'"
                    @click:append="() => (value = !value)"
                    :type="value ? 'text' : 'password'"
                    solo
                  ></v-text-field>
                  <v-text-field
                    v-if="editedIndex > -1"
                    v-model="editedItem.password"
                    :rules="[validationRules.required]"
                    :append-icon="value ? 'visibility_off' : 'visibility'"
                    @click:append="() => (value = !value)"
                    :type="value ? 'text' : 'password'"
                    solo
                  ></v-text-field>

                  <div
                    v-if="validation_errors.password != ''"
                    class="validation-div div-validation"
                  >
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.password"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>
              </v-row>
              <!-- -------------------------------------------------- -->
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <label>{{ $t("Mobile number") }} </label>
                  <v-text-field
                    v-model="editedItem.mobile"
                    :rules="[validationRules.required]"
                    solo
                  ></v-text-field>
                  <div
                    v-if="validation_errors.mobile != ''"
                    class="validation-div div-validation"
                  >
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.mobile"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>
              </v-row>
              <!-- ---------------------------------------------------------- -->
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <label>{{ $t("Role") }} </label>
                  <v-select
                    v-model="editedItem.role"
                    :items="roles"
                    :rules="[validationRules.required]"
                    multiple
                    solo
                    persistent-hint
                    item-text="name"
                    item-value="id"
                  ></v-select>
                  <div
                    v-if="validation_errors.role != ''"
                    class="validation-div div-validation"
                  >
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.role"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>
              </v-row>
              <!-- ---------------------------------------------------------- -->
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn class="modal-btn-cancel" @click="close">{{
            $t("Close")
          }}</v-btn>
          <v-btn
            class="modal-btn-save"
            @click="save"
            :loading="loading"
            :disabled="loading"
            >{{ $t("Save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- --------------snackBar------------------------------ -->
    <v-snackbar v-model="snackbar" :color="snack_color" rounded="pill" top>
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!-- ---------------------end SnackBar--------------------- -->
    <v-row justify="center">
      <v-dialog v-model="confirm_dialog" max-width="500">
        <v-card>
          <v-card-title class="text-h6">
            Are you sure you want to edit data ?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="alert-cancel-btn"
              text
              @click="confirm_dialog = false"
            >
              No
            </v-btn>
            <v-btn class="alert-save-btn" text @click="update"> Yes </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { validationMixin } from "../mixins/validation-mixin";
import HttpService from "../services/HttpService.js";
export default {
  mixins: [validationMixin],
  props: ["addEditDialog", "editedIndex"],
  data: () => ({
    message: "",
    snackbar: false,
    timeout: 190000,
    value: "",
    snack_color: "success",
    confirm_dialog: false,
    loading: false,
    valid: false,
    // editedIndex: -1,
    editedItem: {
      name: "",
      email: "",
      national_id: "",
      password: "",
      mobile: "",
      role: [],
    },
    roles: [],
    defaultItem: {
      name: "",
      email: "",
      national_id: "",
      password: "",
      mobile: "",
      role: "",
    },
    validation_errors: {
      name: [],
      email: [],
      national_id: [],
      password: [],
      mobile: [],
      role: [],
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New Member" : "Edit Member Data";
    },
  },

  methods: {
    setEditedItem(item) {
      this.$nextTick(() => {
        console.log("item come backe from serer = ", item);

        this.editedItem = Object.assign({}, item);
        // this.editedItem = item;
      });
      this.validation_errors = Object.assign(
        {},
        {
          name: [],
          email: [],
          national_id: [],
          password: [],
          mobile: [],
          role: [],
        }
      );
    },
    onFocus() {
      this.$refs.modalForm.resetValidation();
    },

    close() {
      // Child Shouldn't change parent property which is passed as props
      // this.addEditDialog = false;
      // Instead, It should call an event to call a function in parent to change the proerty to false
      this.$emit("closeDialog");
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
      this.$refs.modalForm.resetValidation();
      this.validation_errors = Object.assign(
        {},
        {
          name: [],
          email: [],
          national_id: [],
          password: [],
          mobile: [],
          role: [],
        }
      );
      this.confirm_dialog = false;
    },

    save() {
      if (this.editedIndex > -1) {
        // edit mode
        if (this.valid) {
          this.confirm_dialog = true;
        } else {
          this.$refs.modalForm.validate();
        }
      } else {
        // create mode
        this.store();
      }
    },

    update() {
      if (this.valid) {
        this.loading = true;
        HttpService.post(
          "staff" + "/" + this.editedItem.id,
          this.editedItem,
          true
        ).then((response) => {
          this.message = response.data.status.message;
          if (response.data.status.error == false) {
            this.snack_color = "success";
            this.snackbar = true;
            this.loading = false;
            this.close();
          } else {
            this.loading = false;
            // if there's an error
            if (
              Object.keys(response.data.status.validation_errors).length > 0
            ) {
              // if there's validation errors from backend
              this.validation_errors = response.data.status.validation_errors;
              this.confirm_dialog = false;
            } else {
              // if there's an error but it's not validation error
              this.snack_color = "error";
              this.snackbar = true;
            }
          }
          this.$emit("getData");
        });
      } else {
        this.$refs.modalForm.validate();
      }
    },

    store() {
      if (this.valid) {
        this.loading = true;
        HttpService.post("staff", this.editedItem, true).then((response) => {
          this.message = response.data.status.message;
          if (response.data.status.error == false) {
            this.message = "Staff added successfully";
            this.snack_color = "success";
            this.snackbar = true;
            this.loading = false;
            this.close();
          } else {
            this.loading = false;
            // if there's an error
            if (
              Object.keys(response.data.status.validation_errors).length > 0
            ) {
              // if there's validation errors from backend
              this.validation_errors = response.data.status.validation_errors;
            } else {
              // if there's an error but it's not validation error
              this.snack_color = "error";
              this.snackbar = true;
            }
          }
          this.$emit("getData");
        });
      } else {
        this.$refs.modalForm.validate();
      }
    },

    getRoles() {
      HttpService.get("staff/roles", "", true).then((response) => {
        this.roles = response.data.data;
        // console.log("my roles is = ", this.roles);
      });
    },
  },

  created() {
    this.getRoles();
  },
};
</script>
<style></style>
