<template>
  <!-- Sidebar Area Start Here -->

  <v-navigation-drawer
    v-model="drawer"
    class="main-sidebar not-printable"
    :class="currentAppLocale == 'ar' ? 'text-right' : ''"
    :expand-on-hover="expandOnHover"
    :mini-variant="miniVariant"
    :right="currentAppLocale == 'ar'"
    :src="bg"
    absolute
    dark
    stateless
    value="true"
  >
    <v-list dense nav class="py-0">
      <v-list-item two-line :class="miniVariant && 'px-0'">
        <v-list-item-avatar>
          <img :src="localimage" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
            {{ "iSkola Canteen" }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-list-item v-if="largeScreen">
        <v-btn icon @click.stop="setSideBarMin" v-if="miniVariant">
          <v-icon v-if="currentAppLocale != 'ar'">mdi-chevron-right</v-icon>
          <v-icon v-else>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon @click.stop="setSideBarMin" v-if="!miniVariant">
          <v-icon v-if="currentAppLocale != 'ar'">mdi-chevron-left</v-icon>
          <v-icon v-else>mdi-chevron-right</v-icon>
        </v-btn>
      </v-list-item>

      <!-- <v-list-item v-if="!largeScreen">
        <v-btn icon @click="closeSidebar">
          <v-icon>{{ $t("close") }}</v-icon>
        </v-btn>
      </v-list-item> -->

      <v-list-item link to="/" class="single">
        <v-list-item-icon :class="currentAppLocale == 'ar' ? 'mr-3' : ''">
          <i class="fas fa-chart-line"></i>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ $t("Home") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- masterDatashowMenu -->
      <v-list-group no-action="no-action" ref="schoolSetup" v-if="isSuperAdmin">
        <v-list-item slot="activator">
          <div @click="masterDatashowMenu">
            <v-list-item-icon>
              <!-- <i class="mdi mdi-cog"></i> -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M13.9503 8.78C13.9803 8.53 14.0003 8.27 14.0003 8C14.0003 7.73 13.9803 7.47 13.9403 7.22L15.6303 5.9C15.7803 5.78 15.8203 5.56 15.7303 5.39L14.1303 2.62C14.0303 2.44 13.8203 2.38 13.6403 2.44L11.6503 3.24C11.2303 2.92 10.7903 2.66 10.3003 2.46L10.0003 0.34C9.97025 0.14 9.80025 0 9.60025 0H6.40025C6.20025 0 6.04025 0.14 6.01025 0.34L5.71025 2.46C5.22025 2.66 4.77025 2.93 4.36025 3.24L2.37025 2.44C2.19025 2.37 1.98025 2.44 1.88025 2.62L0.280252 5.39C0.180252 5.57 0.220252 5.78 0.380252 5.9L2.07025 7.22C2.03025 7.47 2.00025 7.74 2.00025 8C2.00025 8.26 2.02025 8.53 2.06025 8.78L0.370252 10.1C0.220252 10.22 0.180252 10.44 0.270252 10.61L1.87025 13.38C1.97025 13.56 2.18025 13.62 2.36025 13.56L4.35025 12.76C4.77025 13.08 5.21025 13.34 5.70025 13.54L6.00025 15.66C6.04025 15.86 6.20025 16 6.40025 16H9.60025C9.80025 16 9.97025 15.86 9.99025 15.66L10.2903 13.54C10.7803 13.34 11.2303 13.07 11.6403 12.76L13.6303 13.56C13.8103 13.63 14.0203 13.56 14.1203 13.38L15.7203 10.61C15.8203 10.43 15.7803 10.22 15.6203 10.1L13.9503 8.78ZM8.00025 11C6.35025 11 5.00025 9.65 5.00025 8C5.00025 6.35 6.35025 5 8.00025 5C9.65025 5 11.0003 6.35 11.0003 8C11.0003 9.65 9.65025 11 8.00025 11Z"
                  fill="white"
                />
              </svg>
              </v-list-item-icon>
          </div>

          <v-list-item-title>{{ $t("Settings") }}</v-list-item-title>
          <v-menu
            v-if="miniVariant"
            v-model="masterDataMenu.value"
            :close-on-click="masterDataMenu.closeOnClick"
            :close-on-content-click="masterDataMenu.closeOnContentClick"
            :offset-x="masterDataMenu.offsetX"
            :offset-y="masterDataMenu.offsetY"
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <i class="fas fa-school"></i>
              </v-list-item-icon>
            </template>
          </v-menu>
        </v-list-item>
        <div>
          <v-list-item
            link
            v-for="(item, index) in schoolSetup"
            :key="index"
            :to="item.to"
          >
            <v-list-item v-if="item.enabled">
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item>
        </div>
      </v-list-group>
      <!-- ----------------------Canteen-Staff---------------------- -->
      <v-list-group no-action="no-action" ref="schoolSetup" v-if="isSuperAdmin">
        <v-list-item slot="activator">
          <div @click="masterDatashowMenu">
            <v-list-item-icon>
              <!-- <i class="fas fa-school"></i> -->
              <i class="fas fa-user"></i>
            </v-list-item-icon>
          </div>

          <v-list-item-title>{{ $t("Staff") }}</v-list-item-title>
          <v-menu
            v-if="miniVariant"
            v-model="masterDataMenu.value"
            :close-on-click="masterDataMenu.closeOnClick"
            :close-on-content-click="masterDataMenu.closeOnContentClick"
            :offset-x="masterDataMenu.offsetX"
            :offset-y="masterDataMenu.offsetY"
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <i class="fas fa-school"></i>
              </v-list-item-icon>
            </template>
          </v-menu>
        </v-list-item>

        <div>
          <v-list-item
            link
            v-for="(item, index) in canteenStaff"
            :key="index"
            :to="item.to"
          >
            <v-list-item v-if="item.enabled">
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item>
        </div>
        <!--  -->
      </v-list-group>
      <!-- -------------end--------------------- -->
      <!-- ----------------------Canteen-Staff---------------------- -->

      <!-- -------------end--------------------- -->
      <!-- ----------------------Product data---------------------- -->
      <v-list-group
        no-action="no-action"
        v-if="isWarehouseStaff || isSuperAdmin"
        ref="schoolSetup"
      >
        <v-list-item slot="activator">
          <div @click="masterDatashowMenu">
            <v-list-item-icon>
              <!-- <i class="fas fa-school"></i> -->
              <i class="fas fa-shopping-basket"></i>
            </v-list-item-icon>
          </div>

          <v-list-item-title>{{ $t("Products") }}</v-list-item-title>

          <v-menu
            v-if="miniVariant"
            v-model="masterDataMenu.value"
            :close-on-click="masterDataMenu.closeOnClick"
            :close-on-content-click="masterDataMenu.closeOnContentClick"
            :offset-x="masterDataMenu.offsetX"
            :offset-y="masterDataMenu.offsetY"
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <i class="fas fa-school"></i>
              </v-list-item-icon>
            </template>
          </v-menu>
        </v-list-item>

        <div>
          <!-- <div v-if="item.enabled"> -->
          <v-list-item
            link
            v-for="(item, index) in productsItems"
            :key="index"
            :to="item.to"
          >
            <v-list-item v-if="item.enabled">
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item>
        </div>
        <!--  -->
      </v-list-group>
      <!-- -------------end--------------------- -->

      <!-- ----------------------Order Cart---------------------- -->
      <v-list-item link to="/orders" class="single" v-if="isCanteenStaff">
        <v-list-item-icon>
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 16.5781C4.9 16.5781 4.01 17.4781 4.01 18.5781C4.01 19.6781 4.9 20.5781 6 20.5781C7.1 20.5781 8 19.6781 8 18.5781C8 17.4781 7.1 16.5781 6 16.5781ZM0 0.578125V2.57812H2L5.6 10.1681L4.25 12.6181C4.09 12.8981 4 13.2281 4 13.5781C4 14.6781 4.9 15.5781 6 15.5781H18V13.5781H6.42C6.28 13.5781 6.17 13.4681 6.17 13.3281L6.2 13.2081L7.1 11.5781H14.55C15.3 11.5781 15.96 11.1681 16.3 10.5481L19.88 4.05813C19.9625 3.90551 20.004 3.73411 20.0005 3.56065C19.9969 3.3872 19.9485 3.21763 19.8598 3.06851C19.7711 2.91939 19.6453 2.79582 19.4946 2.70987C19.3439 2.62392 19.1735 2.57853 19 2.57812H4.21L3.27 0.578125H0ZM16 16.5781C14.9 16.5781 14.01 17.4781 14.01 18.5781C14.01 19.6781 14.9 20.5781 16 20.5781C17.1 20.5781 18 19.6781 18 18.5781C18 17.4781 17.1 16.5781 16 16.5781Z"
              fill="white"
            />
          </svg>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ $t("Orders") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- -------------end--------------------- -->
      <!-- ----------------------Wallet data---------------------- -->
      <v-list-item link to="/points" class="single" v-if="isParent">
        <v-list-item-icon :class="currentAppLocale == 'ar' ? 'mr-3' : ''">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="20"
            height="21"
            viewBox="0 0 24 24"
          >
            <title>wallet-outline</title>
            <path
              d="M5,3C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V16.72C21.59,16.37 22,15.74 22,15V9C22,8.26 21.59,7.63 21,7.28V5A2,2 0 0,0 19,3H5M5,5H19V7H13A2,2 0 0,0 11,9V15A2,2 0 0,0 13,17H19V19H5V5M13,9H20V15H13V9M16,10.5A1.5,1.5 0 0,0 14.5,12A1.5,1.5 0 0,0 16,13.5A1.5,1.5 0 0,0 17.5,12A1.5,1.5 0 0,0 16,10.5Z"
              fill="white"
            />
          </svg>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ $t("Points") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- -------------end--------------------- -->
      <!-- ----------------------OrdersHistory data---------------------- -->
      <v-list-item
        link
        :to="'/OrdersHistory/' + auth_id"
        class="single"
        v-if="isParent || isStudent"
      >
        <v-list-item-icon :class="currentAppLocale == 'ar' ? 'mr-3' : ''">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="20"
            height="21"
            viewBox="0 0 29 29"
          >
            <title>student-wallet-outline</title>
            <path
              xmlns="http://www.w3.org/2000/svg"
              d="M24.3158 3.09165L3.47368 3.09166L3.47368 24.3159L15.6316 24.3159L15.6316 27.7895L3.47368 27.7896C1.56316 27.7896 -1.36656e-07 26.2264 -3.03679e-07 24.3159L-2.12575e-06 3.47376C-2.29278e-06 1.56323 1.56316 7.61573e-05 3.47368 7.59903e-05L24.3158 7.41682e-05C26.2263 7.40012e-05 27.7895 1.56323 27.7895 3.47376L27.7895 15.6317L24.3158 15.6317L24.3158 3.09165Z"
              fill="white"
            />
            <path
              xmlns="http://www.w3.org/2000/svg"
              d="M6.94739 15.6316L6.94739 12.1579L20.8421 12.1579L20.8421 15.6316L15.6316 15.6316L6.94739 15.6316Z"
              fill="white"
            />
            <path
              xmlns="http://www.w3.org/2000/svg"
              d="M20.8421 10.4211L6.94739 10.4211L6.94739 6.94746L20.8421 6.94746L20.8421 10.4211Z"
              fill="white"
            />
            <path
              xmlns="http://www.w3.org/2000/svg"
              d="M23.2736 30.221C22.5094 30.221 21.8911 30.8463 21.8911 31.6105C21.8911 32.3747 22.5094 33 23.2736 33C24.0378 33 24.6631 32.3747 24.6631 31.6105C24.6631 30.8463 24.0378 30.221 23.2736 30.221ZM19.1053 19.1052V20.4947H20.4948L22.9957 25.7678L22.0579 27.4699C21.9467 27.6644 21.8842 27.8936 21.8842 28.1368C21.8842 28.901 22.5094 29.5263 23.2736 29.5263H31.6102V28.1368H23.5654C23.4681 28.1368 23.3917 28.0604 23.3917 27.9631L23.4126 27.8798L24.0378 26.7473H29.2134C29.7345 26.7473 30.193 26.4625 30.4292 26.0318L32.9162 21.5229C32.9736 21.4169 33.0024 21.2978 32.9999 21.1773C32.9975 21.0568 32.9638 20.939 32.9022 20.8354C32.8406 20.7318 32.7532 20.6459 32.6485 20.5862C32.5438 20.5265 32.4254 20.495 32.3049 20.4947H22.0301L21.3771 19.1052H19.1053ZM30.2208 30.221C29.4566 30.221 28.8383 30.8463 28.8383 31.6105C28.8383 32.3747 29.4566 33 30.2208 33C30.9849 33 31.6102 32.3747 31.6102 31.6105C31.6102 30.8463 30.9849 30.221 30.2208 30.221Z"
              fill="white"
            />
            <rect
              xmlns="http://www.w3.org/2000/svg"
              x="6.94739"
              y="17.3684"
              width="8.68421"
              height="3.47368"
              fill="white"
            />
          </svg>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ $t("Order List") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- -------------end--------------------- -->
      <!-- ----------------------  Transfers data  ---------------------- -->
      <v-list-item link :to="'/Transfers/'" class="single" v-if="isParent">
        <v-list-item-icon :class="currentAppLocale == 'ar' ? 'mr-3' : ''">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="21"
            viewBox="0 0 29 29"
            fill="none"
          >
            <g clip-path="url(#clip0_429_5230)">
              <path
                d="M-1.57351e-05 0.0144402L28.0041 0L28.0185 28.0041L0.0144244 28.0185L-1.57351e-05 0.0144402Z"
                fill="white"
              />
              <path
                d="M2.33847 9.34797L7.00342 4.67821L7.00522 8.17873L24.5078 8.1697L24.509 10.5034L7.00642 10.5124L7.00823 14.0129L2.33847 9.34797Z"
                fill="#7297FF"
              />
              <path
                d="M25.68 18.6705L21.0151 23.3403L21.0133 19.8398L3.51071 19.8488L3.5095 17.5151L21.0121 17.5061L21.0103 14.0056L25.68 18.6705Z"
                fill="#7297FF"
              />
            </g>
            <defs>
              <clipPath id="clip0_429_5230">
                <rect
                  width="28.0041"
                  height="28.0041"
                  rx="3"
                  transform="matrix(-1 0.000515645 0.000515645 1 28.0041 0)"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ $t("Transfers") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- -------------end--------------------- -->

      <!-------------------------- Accountant Transfers -------------------------->
      <v-list-item link :to="'/Recharges/'" class="single" v-if="isAccountant">
        <v-list-item-icon :class="currentAppLocale == 'ar' ? 'mr-3' : ''">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="21"
            viewBox="0 0 29 29"
            fill="none"
          >
            <g clip-path="url(#clip0_429_5230)">
              <path
                d="M-1.57351e-05 0.0144402L28.0041 0L28.0185 28.0041L0.0144244 28.0185L-1.57351e-05 0.0144402Z"
                fill="white"
              />
              <path
                d="M2.33847 9.34797L7.00342 4.67821L7.00522 8.17873L24.5078 8.1697L24.509 10.5034L7.00642 10.5124L7.00823 14.0129L2.33847 9.34797Z"
                fill="#7297FF"
              />
              <path
                d="M25.68 18.6705L21.0151 23.3403L21.0133 19.8398L3.51071 19.8488L3.5095 17.5151L21.0121 17.5061L21.0103 14.0056L25.68 18.6705Z"
                fill="#7297FF"
              />
            </g>
            <defs>
              <clipPath id="clip0_429_5230">
                <rect
                  width="28.0041"
                  height="28.0041"
                  rx="3"
                  transform="matrix(-1 0.000515645 0.000515645 1 28.0041 0)"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ $t("Recharges") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!------------------------ Academic Setup -------------------------->

      <!-- ----------------end --------------------------->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import ACL from "../acl";

export default {
  name: "SideBar",
  props: {},
  data() {
    return {
      currentAppLocale: localStorage.locale,
      /* ===========sidebar data =================== */
      isWarehouseStaff: ACL.Role("Warehouse Staff"),
      isCanteenStaff: ACL.Role("Canteen Staff"),
      isSuperAdmin: ACL.Role("Super-Admin"),
      isParent: ACL.Role("Parent"),
      isStudent: ACL.Role("Student"),
      isAccountant: ACL.Role("Accountant"),

      schoolSetup: [
        {
          title: "School",
          to: "/school",
          enabled: true,
        },
        {
          title: "Canteens",
          to: "/canteens",
          enabled: true,
        },
      ],
      canteenStaff: [
        {
          title: "Staff Members",
          to: "/Staff-Members",
          enabled: true,
        },
      ],
      productsItems: [
        {
          title: "Product Data",
          to: "/productData",
          enabled: true,
        },
        {
          title: "Quantities",
          to: "/quantities",
          enabled: true,
        },
      ],
      ordersItems: [
        {
          title: "Orders",
          to: "/orders",
          enabled: true,
        },
      ],
      auth_id: localStorage.getItem("auth_id"),

      miniVariant: false,
      expandOnHover: false,
      background: false,
      mini: true,
      localimage:
        localStorage["school_logo"] != undefined &&
        localStorage["school_logo"] != "null"
          ? localStorage["school_logo"]
          : require("../assets/logo.png"),

      masterDataMenu: {
        offsetX: true,
        offsetY: true,
        value: false,
        closeOnClick: true,
        closeOnContentClick: true,
      },

      //   offsetX: true,
      //   offsetY: true,
      //   value: false,
      //   closeOnClick: true,
      //   closeOnContentClick: true,
      // },
      logo: "",
      school_name: "",
    };
  },

  computed: {
    getSideBarMin() {
      return this.$store.state.sidebarMin;
    },
    drawer: {
      // getter
      get: function () {
        return this.$vuetify.breakpoint.name == "xs" ||
          this.$vuetify.breakpoint.name == "sm"
          ? this.$store.getters.getSidebarDisplay
          : true;
      },
      set: function () {},
    },
    bg() {
      return this.background
        ? "https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg"
        : undefined;
    },
    largeScreen() {
      return this.$vuetify.breakpoint.name == "xs" ||
        this.$vuetify.breakpoint.name == "sm"
        ? false
        : true;
    },
  },
  methods: {
    masterDatashowMenu() {
      if (this.miniVariant == true) {
        this.masterDataMenu.value = true;
      }
    },

    getForm() {
      this.$router.push("/MasterData");
    },
    setSideBarMin() {
      this.miniVariant = !this.miniVariant;
      this.$store.commit("setSideBarMin");
    },
    closeSidebar() {
      console.log("ACL.Role('Super-Admin')", ACL.Role("Super-Admin"));
      this.$store.commit("setSidebarDisplay");
      // this.drawer = false;
    },
    canSee() {
      return true;
      // return (
      //   arr.find((element) => {
      //     return element.enabled == true;
      //   }) != undefined
      // );
    },
    // menuHasActive() {
    //   let path = window.location.pathname;
    //   let arraySchoolSetup = [
    //     "/MasterData",
    //     "/academics",
    //     "/subject_type",
    //     "/subjects",
    //     "/grades",
    //     "/workingdays",
    //     "/gradingsystem",
    //   ];
    //   let arrayAcademicSetup = [
    //     "/curriculum_setup/active",
    //     "/qualifier",
    //     "/classmanagement/all",
    //   ];
    //   let arrayStudents = ["/applicants", "/admissionExam", "/students"];

    //   let arrayTeachers = ["/teachers"];

    //   let arrayFianacial = [
    //     "/financialstatus",
    //     "/feeplans",
    //     "/taxes",
    //     "/cashpoints",
    //     "/partners",
    //     "/invoices",
    //     "/payments",
    //     "/bankReceipts",
    //     "/allAccounts/types",
    //     "/accounts",
    //     "/discount_packages",
    //   ];

    //   let arrayClassRoom = [
    //     "/attendance/students",
    //     "/discussionrooms",
    //     "/homework",
    //     "/digital_library",
    //     "/studentAttendance",
    //     "/attendanceChild",
    //   ];

    //   let arrayItemsSetup = ["/itemsCategories", "/items"];

    //   let arrayWarehouses = [
    //     "/WarehouseTransaction",
    //     "/BooksList",
    //     "/WarehouseItems",
    //   ];

    //   let arrayNotifications = ["/notificationsLogs", "/notificationTemplates"];

    //   let arrayHrmodule = [
    //     "/hrConfigrations",
    //     "/employees",
    //     "/allEmployees/salaries",
    //     "/MasterAttendance",
    //     "/VacationRequests",
    //   ];

    //   let arrayUsers = ["/user_management", "/all_users"];

    //   if (arraySchoolSetup.includes(path)) {
    //     this.$refs.schoolSetup.click();
    //   } else if (arrayAcademicSetup.includes(path)) {
    //     this.$refs.academicSetup.click();
    //   } else if (arrayStudents.includes(path)) {
    //     this.$refs.students.click();
    //   } else if (arrayTeachers.includes(path)) {
    //     this.$refs.teachers.click();
    //   } else if (arrayFianacial.includes(path)) {
    //     this.$refs.fianacial.click();
    //   } else if (arrayClassRoom.includes(path)) {
    //     this.$refs.classRoom.click();
    //   } else if (arrayItemsSetup.includes(path)) {
    //     this.$refs.itemsSetup.click();
    //   } else if (arrayWarehouses.includes(path)) {
    //     this.$refs.Warehouses.click();
    //   } else if (arrayNotifications.includes(path)) {
    //     this.$refs.Notifications.click();
    //   } else if (arrayHrmodule.includes(path)) {
    //     this.$refs.hrmodule.click();
    //   } else if (arrayUsers.includes(path)) {
    //     this.$refs.Users.click();
    //   }
    // },
  },
  mounted() {
    this.school_name =
      localStorage.school_name && localStorage.school_name != "null"
        ? localStorage.school_name
        : "SMS";
    // this.logo = localStorage.logo ? localStorage.logo : "null";
    // this.menuHasActive();
  },
};
</script>

<style scoped lang="scss">
.main-sidebar {
  background-color: #7297ff !important;
  z-index: 11;
}

.v-list-item {
  min-height: 35px;
}

.single {
  padding-left: 16px !important;
}
</style>
